/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'group': {
    width: 56,
    height: 35,
    viewBox: '0 0 56 35',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M27.995 2a5.15 5.15 0 00-5.145 4.922c.006 1.486.625 3.632 1.664 5.41.516.886 1.108 1.63 1.728 2.141.618.511 1.21.75 1.758.75s1.139-.239 1.758-.75c.62-.514 1.212-1.258 1.728-2.144 1.014-1.737 1.628-3.822 1.663-5.297a.983.983 0 01-.008-.09A5.15 5.15 0 0027.995 2zm7.137 4.71c.012.061.018.126.018.192 0 1.91-.755 4.412-1.936 6.435-.596 1.021-1.329 1.97-2.181 2.676-.853.706-1.88 1.21-3.033 1.21-1.152 0-2.179-.503-3.032-1.208-.853-.705-1.586-1.654-2.181-2.674-1.182-2.022-1.937-4.525-1.937-6.439v-.04a7.15 7.15 0 0114.282-.153zM7.317 6.583a6.205 6.205 0 0110.593 4.388c0 1.629-.65 3.799-1.656 5.558-.508.889-1.136 1.726-1.873 2.352-.736.626-1.641 1.09-2.671 1.09-1.027 0-1.932-.46-2.669-1.083-.737-.623-1.366-1.456-1.875-2.343-.976-1.702-1.616-3.793-1.654-5.42a1 1 0 01-.012-.154c0-1.646.654-3.224 1.817-4.388zm.185 4.26c.005.042.008.085.008.128 0 1.223.516 3.053 1.391 4.579.433.754.924 1.382 1.431 1.811.507.429.97.61 1.378.61.405 0 .868-.182 1.375-.614.508-.431 1-1.064 1.433-1.821.877-1.533 1.392-3.363 1.392-4.565a4.205 4.205 0 00-8.408-.127zm38.793-4.077a4.205 4.205 0 00-4.205 4.205c0 1.202.515 3.032 1.392 4.565.433.757.925 1.39 1.433 1.821.508.432.97.614 1.375.614.408 0 .87-.181 1.378-.61.507-.429.998-1.057 1.43-1.811.876-1.527 1.392-3.356 1.392-4.579 0-.043.003-.086.008-.127a4.206 4.206 0 00-4.203-4.078zm6.193 4.359c.008-.051.012-.102.012-.154a6.205 6.205 0 00-12.41 0c0 1.629.65 3.799 1.656 5.558.508.889 1.136 1.726 1.873 2.352.736.626 1.641 1.09 2.671 1.09 1.028 0 1.932-.46 2.669-1.083.737-.623 1.366-1.456 1.875-2.343.976-1.702 1.616-3.793 1.654-5.42zM28 21.841a11.66 11.66 0 00-11.66 11.66 1 1 0 11-2 0c0-2.908.927-5.72 2.617-8.04A9.72 9.72 0 002 33.651a1 1 0 11-2 0 11.72 11.72 0 0118.26-9.728l.081-.081a13.66 13.66 0 0119.318 0l.081.081A11.719 11.719 0 0156 33.653a1 1 0 01-2 0 9.721 9.721 0 00-14.957-8.192 13.66 13.66 0 012.617 8.04 1 1 0 11-2 0A11.66 11.66 0 0028 21.842z" _fill="#3C8AFF"/>'
  }
})
